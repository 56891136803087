import React from 'react';
import { StickyProvider } from 'contexts/app/app.provider';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import KeyFeature from 'sections/key-feature';
import CoreFeature from 'sections/core-feature';
import CoreFeatureWorkShop from 'sections/core-features-workshop';
import Feature from 'sections/feature';
import FeatureWorkshops from 'sections/feature-workshops';
import PartnerSection from 'sections/partner';
import WorkFlow from 'sections/workflow';
import TestimonialCard from 'sections/testimonial';
import SecurePayment from 'sections/secure-payment';
import Package from 'sections/package';
import Faq from 'sections/faq';
import Contact from 'sections/contact';

// Load other package css file
import 'react-multi-carousel/lib/styles.css';
import 'react-modal-video/css/modal-video.min.css';
import 'rc-drawer/assets/index.css';

export default function IndexPage() {
  return (
    <StickyProvider>
      <Layout>
        <SEO title="Garaaz - Marketplace & SaaS for automobile spare parts" description="Omni channel marketplace and SaaS for distribution of spare parts in the automobile industry" keywords="garaaz, spare parts, automobile, svan autotech, automobile" />
        <Banner />
        <KeyFeature />
        <CoreFeature />
        <Feature />
        <CoreFeatureWorkShop />
        <FeatureWorkshops />
        {/* <PartnerSection /> */}
        {/* <WorkFlow /> */}
        <TestimonialCard />
        {/* <SecurePayment /> */}
        <Package />
        {/* <Faq /> */}
        <Contact />
      </Layout>
    </StickyProvider>
  );
}
