/** @jsx jsx */
import { jsx, Container, Box } from 'theme-ui';
import TextFeature from 'components/text-feature';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const data = {
  title: 'Get in touch',
  description:
    'If you plan to start using Garaaz to manage your distribution centers, workshop or if you want to work with us. ',
  btnName: 'Contact Us',
  btnURL: 'mailto:varun@garaaz.com',
};

export default function PartnerSection() {
  return (
    <section id="contact" sx={{ variant: 'section.partner' }}>
      <Container sx={styles.containerBox}>
        <Box sx={styles.contentBox}>
          <TextFeature
            subTitle={data.subTitle}
            title={data.title}
            description={data.description}
            btnName={data.btnName}
            btnURL={data.btnURL}
          />
        </Box>
      </Container>
    </section>
  );
}
const styles = {
  containerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: ['wrap', null, null, 'nowrap'],
    textAlign: 'center'
  },
  thumbnail: {
    px: [0, null, '40px', 0],
    width: ['100%', null, null, 360, 550, 655, null, 765],
  },
  contentBox: {
    flexShrink: 0,
    px: [0, null, '30px', 0],
    textAlign: ['center', null, null, 'left'],
    width: ['100%', 450, 550, '350px', '410px', '485px'],
    mx: 'auto',
    pb: ['30px', null, null, 0],
    '.description': {
      pr: [0, null, null, null, 4],
    },
    textAlign: 'center',
  },
};
